const DOMAIN = 'pm-dev-api.calenso.com';
const BASE_URL = 'https://' + DOMAIN;

export const environment = {
  apiUrl: BASE_URL + '/api/v1/',
  appointmentConfirmationPageUrl: 'https://pm-dev-dashboard.calenso.com/appointments/',
  avatarImagePath: BASE_URL + '/files/workers/avatar/',
  backgroundImagePath: BASE_URL + '/files/partners/booking_page_background_logo/',
  baseUrl: BASE_URL,
  dashboardUrl: 'https://pm-dev-dashboard.calenso.com',
  defaultBookingPartnerName: 'calenso',
  deployUrl: '',
  distBrowserFolderPath: '/home/node/app/browser',
  encryptedKey: 'FtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ',
  eventImagePath: BASE_URL + '/files/events/image/',
  eventImgFallback: 'calenso-default-logo.svg',
  faviconImagePath: BASE_URL + '/files/partners/favicon_logo/',
  landingPageImagePath: BASE_URL + '/files/partners/landingpage_logo/',
  partnersImagePath: BASE_URL + '/files/partners/logo/',
  production: false,
  ssrPM2Port: 4000,
  version: 'v6.7.0',
  webcomponentWidgetUrl: 'https://pm-dev-webcomponent.calenso.com',
};
