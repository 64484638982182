import { HttpErrorResponse, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { PartnerService } from '@services/feature-service/partner.service';
import { LoggerService } from '@services/util-service/logger.service';
import { catchError, map } from 'rxjs/operators';

export const HttpTokenInterceptor: HttpInterceptorFn = (request, next) => {
  const partnerService = inject(PartnerService);
  const headers: { [name: string]: string | string[] } = {};

  if (partnerService?.accessToken) {
    headers['Authorization'] = `Bearer ${partnerService?.accessToken}`;
    headers['X-Calenso-Auth'] = 'true';
  }

  request = request.clone({ setHeaders: headers });
  return next(request);
}

export const HttpErrorInterceptor: HttpInterceptorFn = (request, next) => {
  const partnerService = inject(PartnerService);
  const headers: { [name: string]: string | string[] } = {};

  if (partnerService?.accessToken) {
    headers['Authorization'] = `Bearer ${partnerService?.accessToken}`;
    headers['X-Calenso-Auth'] = 'true';
  }

  request = request.clone({ setHeaders: headers });
  return next(request).pipe(
    map(event => {
      if (event instanceof HttpResponse) {
        const body = event.body as any;
        if (body?.errors && body.errors?.length) {
          throw new HttpErrorResponse({
            error: new Error(body.errors[0].message),
            statusText: body.errors[0].message,
            status: body.errors[0].code
          });
        }
        return event;
      }
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        LoggerService.warn('Authentication failed!');
      }
      LoggerService.error(error);
      throw error;
    })
  );
}

