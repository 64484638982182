export const fi_FI = {
  "error_messages": {
    "xss_error_message": "Tämä teksti sisältää HTML-tageja, joita ei tueta.",
    "configuration_error_title": "Konfigurointivirhe",
    "configuration_error_message": "Varauspalvelu ei ole käytössä.",
    "invalid_partner_title": "Kumppanin osoite on virheellinen",
    "invalid_partner_message": "Valitettavasti emme löytäneet kumppania antamastasi osoitteesta. Yritä toista osoitetta.",
    "subscription_missing_title": "Tilaus puuttuu",
    "subscription_missing_message": "Valitettavasti aktiivista tilausta ei löytynyt."
  },
  "general": {
    "mon": "Ma",
    "tue": "Ti",
    "wed": "Ke",
    "thu": "To",
    "fri": "Pe",
    "sat": "La",
    "sun": "Su",
    "monday": "Maanantai",
    "tuesday": "Tiistai",
    "wednesday": "Keskiviikko",
    "thursday": "Torstai",
    "friday": "Perjantai",
    "saturday": "Lauantai",
    "sunday": "Sunnuntai",
    "close": "Sulje",
    "load_more": 'Lataa lisää'
  },
  "navigation_title": {
    "services": "Palveluita",
    "events": "Tapahtumia"
  },
  "booking_page": {
    "partner_header": {
      "provider": "Palveluntarjoaja",
      "employees": "Työntekijät",
      "resource": "Resurssi",
      "description": "Kuvaus",
      "email": "Sähköposti",
      "website": "Verkkosivusto",
      "address": "Osoite",
      "mobile": "Puhelinnumero"
    },
    "menu": {
      "book_appointment": "Varaa aika",
      "book_event": "Varaa tapahtuma",
      "employees": "Työntekijät",
      "branches": "Toimipisteet",
      "about_us": "Tietoa meistä",
      "reviews": "Arvostelut"
    },
    "contact_information": {
      "contact_information": "Yhteystiedot",
      "opening_hours": "Aukioloajat",
      "closed": "Suljettu"
    },
    "hide_menu_tooltip": "Piilota valikko varaussivulla",
    "show_menu_tooltip": "Näytä valikko varaussivulla",
    "edit": "Muokkaa",
    "edit_bussiness_hours": "Muokkaa aukioloaikoja"
  },
  "event": {
    "bookable_events": "Varattavissa olevat tapahtumat/ryhmätilaisuudet",
    "list": "Lista",
    "grid": "Ruudukko",
    "store_filter": "Tallentaa suodatin",
    "category_filter": "Luokan suodatin",
    "all": "Kaikki",
    "book": "Kirja",
    "no_place_defined": "Paikkaa ei ole määritelty",
    "free": "Vapaa",
    "hidden": "Piilotettu",
    "no_events": "Tämä palveluntarjoaja ei vielä määrittänyt tapahtumia.",
    "hidden_events_message": "Tämän kumppanin tapahtumat ovat piilossa. Käytä rekisteröitymiseen suoraa linkkiä kumppanilta saamaasi tapahtumaan.",
    "filter_result_null": "Kriteereitäsi vastaavia tuloksia ei ole.",
    "no_regular_count": "Ei vapaita paikkoja",
    "regular_count_singular": "1 vapaa paikka",
    "regular_count_plural": "{{ number }} vapaata paikkaa",
    "fully_booked": "Täysin varattu"
  },
  "event_details": {
    "place": "Toteutuspaikka",
    "price": "Hinta",
    "meeting_type_helper_text": "Calenso luo verkkokokouksen heti, kun asiakas rekisteröityy.",
    "taxes": "(sis. {{percentage}}% alv)",
    "free": "Ilmainen",
    "hidden": "Piilotettu",
    "view_on_map": "Näytä kartalla",
    "error_while_loading_map": "Karttaa ei voitu ladata. Yritä uudelleen!"
  },
  "conference": {
    "error_messages": {
      "invalid_link": "Vaikuttaa siltä, että annettu konferenssilinkki on virheellinen. Tarkista linkki ja yritä uudelleen.",
      "expired_link": "Pahoittelumme, mutta tämä konferenssilinkki on vanhentunut. Ota yhteyttä järjestäjään saadaksesi lisäapua.",
      "not_belonging": "Tätä varauslinkkiä on jo käytetty, joten sinut ohjataan suoraan ajan vahvistussivulle."
    },
    "attendees": "Osallistujat",
    "more_attendees": "+{{ count }} Lisää",
    "less_attendees": "Näytä vähemmän osallistujia",
    "optional": "Valinnainen",
    "required": "Vaaditaan"
  },
  "footer": {
    "copyright_text": " - Copyright {{years}} - Kaikki oikeudet pidätetään."
  },
  "page_not_found_component": {
    "subtitle_1": "Hups, näyttää siltä, että olet eksyksissä...",
    "subtitle_2": "Käytä tätä tilaisuutta rentoutua hetki.",
    "text": "Rentoudu seuraavat kolme minuuttia ja suorita alla olevan videon venytysharjoitus. Lyhyen istunnon jälkeen kehosi vapautuu negatiivisista tunteista ja tunnet olosi jälleen vastasyntyneeksi.",
    "play_video": "Toista harjoitusvideo"
  }
};
