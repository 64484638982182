import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { GlobalErrorHandler } from '@interceptors/global-error-handler.interceptor';
import { HttpErrorInterceptor, HttpTokenInterceptor } from '@interceptors/http-interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { appRoutes } from '@routes/app.routes';
import * as Sentry from "@sentry/angular-ivy";
import { Observable, of } from 'rxjs';
import { de_CH, en_US, es_ES, fi_FI, fr_CH, it_CH, nl_NL, pl_PL, sv_SE } from './i18n/';

// locals import required for currency pipe and date pipe
import localeDe from '@angular/common/locales/de';
import localeDeCH from '@angular/common/locales/de-CH';
import localeEs from '@angular/common/locales/es';
import localFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeFrCH from '@angular/common/locales/fr-CH';
import localeIt from '@angular/common/locales/it';
import localeItCh from '@angular/common/locales/it-CH';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeSv from '@angular/common/locales/sv';

// register locals for currency pipe and date pipe
registerLocaleData(localeDe, 'de');
registerLocaleData(localeDeCH, 'ch');
registerLocaleData(localeEs, 'es');
registerLocaleData(localFi, 'fi');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeFrCH, 'fr_CH');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeItCh, 'it_CH');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeSv, 'sv');

export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'de_CH': return of(de_CH);
      case 'en_US': return of(en_US);
      case 'fi_FI': return of(fi_FI);
      case 'fr_CH': return of(fr_CH);
      case 'it_CH': return of(it_CH);
      case 'nl_NL': return of(nl_NL);
      case 'pl_PL': return of(pl_PL);
      case 'es_ES': return of(es_ES);
      case 'sv_SE': return of(sv_SE);
      default: return of(de_CH);
    }
  }
}

export function getLocalStorage() {
  return (typeof window !== 'undefined') ? window.localStorage : null;
}

export function getSessionStorage() {
  return (typeof window !== 'undefined') ? window.sessionStorage : null;
}

const interceptors = [HttpTokenInterceptor, HttpErrorInterceptor];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideAnimations(),
    provideClientHydration(),
    provideHttpClient(
      withFetch(),
      withInterceptors(interceptors)
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: CustomTranslateLoader
        }
      }),
      MatDialogModule
    ),
    { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
    { provide: 'SESSIONSTORAGE', useFactory: getSessionStorage },
    { provide: LOCALE_ID, useValue: 'de-ch' },
    { provide: APP_ID, useValue: 'calio-booking-page' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },
    CalioTranslationPipe
  ]
};
