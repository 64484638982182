export const pl_PL = {
  "error_messages": {
    "xss_error_message": "Ten tekst zawiera nieobsługiwane znaczniki HTML.",
    "configuration_error_title": "Błąd konfiguracji",
    "configuration_error_message": "Usługa rezerwacji nie jest włączona.",
    "invalid_partner_title": "Nieprawidłowy adres partnera",
    "invalid_partner_message": "Niestety nie mogliśmy znaleźć partnera pod podanym adresem. Spróbuj użyć innego adresu.",
    "subscription_missing_title": "Brak subskrypcji",
    "subscription_missing_message": "Niestety, nie znaleziono aktywnej subskrypcji."
  },
  "general": {
    "mon": "Pon",
    "tue": "Wt",
    "wed": "Śr",
    "thu": "Czw",
    "fri": "Pt",
    "sat": "Sob",
    "sun": "Niedz",
    "monday": "Poniedziałek",
    "tuesday": "Wtorek",
    "wednesday": "Środa",
    "thursday": "Czwartek",
    "friday": "Piątek",
    "saturday": "Sobota",
    "sunday": "Niedziela",
    "close": "Zamknij",
    "load_more": "Załaduj więcej"
  },
  "navigation_title": {
    "services": "Usługi",
    "events": "Wydarzenia"
  },
  "booking_page": {
    "partner_header": {
      "provider": "Dostawca",
      "employees": "Pracowniczka/pracownik",
      "resource": "Zasoby",
      "description": "Opis",
      "email": "E-mail",
      "website": "Strona internetowa",
      "address": "Adres",
      "mobile": "Telefon"
    },
    "menu": {
      "book_appointment": "Zarezerwuj spotkanie",
      "book_event": "Zarezerwuj wydarzenie",
      "employees": "Pracownicy",
      "branches": "Oddziały",
      "about_us": "O nas",
      "reviews": "Recenzje"
    },
    "contact_information": {
      "contact_information": "Informacje kontaktowe",
      "opening_hours": "Godziny otwarcia",
      "closed": "Zamknięte"
    },
    "hide_menu_tooltip": "Ukryj menu na stronie rezerwacji",
    "show_menu_tooltip": "Pokaż menu na stronie rezerwacji",
    "edit": "Edytuj",
    "edit_bussiness_hours": "Edytuj godziny pracy"
  },
  "event": {
    "bookable_events": "Nasze wydarzenia/spotkania grupowe do rezerwacji",
    "list": "Lista",
    "grid": "Siatka",
    "store_filter": "Przechowuj filtr",
    "category_filter": "Filtr kategorii",
    "all": "Wszystko",
    "book": "Książka",
    "no_place_defined": "Nie określono miejsca",
    "free": "Bezpłatny",
    "hidden": "Ukryty",
    "no_events": "Ten dostawca nie określił jeszcze żadnych wydarzeń.",
    "hidden_events_message": "Wydarzenia tego partnera są ukryte.",
    "filter_result_null": "Nie ma wyników spełniających Twoje kryteria.",
    "no_regular_count": "Brak wolnych miejsc",
    "regular_count_singular": "1 wolne miejsce",
    "regular_count_plural": "{{ number }} wolne miejsca",
    "fully_booked": "W pełni zarezerwowane"
  },
  "event_details": {
    "place": "Miejsce realizacji",
    "price": "Cena",
    "meeting_type_helper_text": "Calenso generuje spotkanie online, gdy tylko klient się zarejestruje",
    "taxes": "(w tym {{percentage}}% VAT)",
    "free": "Bezpłatnie",
    "hidden": "Ukryte",
    "view_on_map": "Wyświetl na mapie",
    "error_while_loading_map": "Nie można załadować mapy. Spróbuj ponownie!"
  },
  "conference": {
    "error_messages": {
      "invalid_link": "Wygląda na to, że podany link do konferencji jest nieprawidłowy. Proszę sprawdź link i spróbuj ponownie.",
      "expired_link": "Przykro nam, ale ten link do konferencji wygasł. Skontaktuj się z organizatorem w celu uzyskania dalszej pomocy.",
      "not_belonging": "Ups, wygląda na to, że ta konferencja jest powiązana z innym partnerem.",
      "booked": "Ten link do rezerwacji został już użyty, więc zostaniesz bezpośrednio przekierowany na stronę potwierdzenia spotkania."
    },
    "attendees": "Uczestnicy",
    "more_attendees": "+{{ count }} Więcej",
    "less_attendees": "Pokaż mniej uczestników",
    "optional": "Opcjonalnie",
    "required": "Wymagane"
  },
  "footer": {
    "copyright_text": " - Copyright {{years}} - Wszelkie prawa zastrzeżone."
  },
  "page_not_found_component": {
    "subtitle_1": "Ups, wygląda na to, że się zgubiłeś...",
    "subtitle_2": "Skorzystaj z okazji i zrelaksuj się przez chwilę.",
    "text": "Zrelaksuj się przez następne trzy minuty i wykonaj ćwiczenie rozciągające z poniższego filmu. Po krótkiej sesji Twoje ciało będzie wolne od negatywnych uczuć i znów poczujesz się jak nowo narodzony.",
    "play_video": "Odtwórz wideo z ćwiczeniami"
  }
};
